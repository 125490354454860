<template>
  <component :is="component"></component>
</template>

<script>
import { mapGetters } from 'vuex';
import PersonaConstants from '@/shared/constants/PersonaConstants';
export default {
  name: 'ProductViewManager',

  components: {
    BuyerProductView: () => import('./BuyerProductView'),
    VendorProductView: () => import('./VendorProductView'),
    SuperProductView: () => import('./SuperProductView'),
  },
  mounted() {
  },
  computed: {
    ...mapGetters('auth', ['user']),
    component() {
      const { persona } = this.user || '';
      switch (persona) {
        case PersonaConstants.super.value:
          return 'SuperProductView';
        case PersonaConstants.vendor.value:
          return 'VendorProductView';
        case PersonaConstants.buyer.value:
        default:
          return 'BuyerProductView';
      }
    },
  },
};
</script>
